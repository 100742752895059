import { bot_menu_plan_plan_axo_axo, bot_menu_plan, bot_menu_plan_axo, bot_menu_plan_axo_axo, bot_menu_plan_orbit, bot_menu_plan_orbit_axo, bot_menu_plan_orbit_axo_axo, bot_menu_plan_plan, leftMenuBase, leftMenuHouse, bot_menu_plan_axo_tour, leftMenuTour, bot_menu_sky, bot_menu_plan_axo_orbit_tour, bot_menu_plan_axo_iframe, houseMenu, buildingMenu, bot_menu_ret, bot_menu_plan_iframe } from "./menus";

export default {
    // Just plan
    poi_p_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan,
    },

    // Just plan duplex
    poi_pp_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan,
    },

    poi_pp_plan_2: {
        image: "%plan_2%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan,
    },

    // Plan Axo
    poi_pai_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_iframe,
    },
    poi_pai_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_iframe,
    },
    poi_pai_iframe: {
        iframe: "%iframe%",
        back: "layout",
        // infoCard: "%id%",
        // botMenu: bot_menu_plan_axo_iframe,
    },

    // Plan iframe
    poi_pi_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_iframe,
    },
    poi_pi_iframe: {
        iframe: "%iframe%",
        // infoCard: "%id%",
        botMenu: bot_menu_plan_iframe,
    },
    poi_pi_iframe2: {
        iframe: "%iframe2%",
        // infoCard: "%id%",
        // botMenu: bot_menu_plan_iframe,
        back : "layout"
    },


    // Plan Axo
    poi_pa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },
    poi_pa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },

    // Plan Axo Tour
    poi_pat_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_tour,
    },
    poi_pat_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_tour,
    },
    poi_pat_tour: {
        tour: "%tour%",
        back: "scene",
        botMenu: bot_menu_plan_axo_tour,
    },

    // Plan Axo Orbit Tour
    poi_paot_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_orbit_tour,
    },
    poi_paot_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_orbit_tour,
    },
    poi_paot_orbit: {
        orbit: "%orbit%",
        back: "scene",
        botMenu: bot_menu_plan_axo_orbit_tour,
    },
    poi_paot_tour: {
        tour: "%tour%",
        back: "scene",
        botMenu: bot_menu_plan_axo_orbit_tour,
    },

    // Plan Plan Axo Axo
    poi_ppaa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan_axo_axo,
    },
    poi_ppaa_plan_2: {
        image: "%plan_2%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan_axo_axo,
    },
    poi_ppaa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan_axo_axo,
    },
    poi_ppaa_axo_2: {
        image: "%axo_2%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan_axo_axo,
    },

    // Plan axo axo
    poi_paa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_axo,
    },
    poi_paa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_axo,
    },
    poi_paa_axo_2: {
        image: "%axo_2%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_axo,
    },

    // Plan orbit 
    poi_po_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit,
    },
    poi_po_orbit: {
        orbit: "%orbit%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit,
        leftMenu: leftMenuHouse,
        compas: true,
    },

    // Plan orbit axo
    poi_poa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo,
    },
    poi_poa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo,
    },
    poi_poa_orbit: {
        orbit: "%orbit%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo,
        leftMenu: leftMenuHouse,
        compas: true,
    },

    // Plan orbit axo axo
    poi_poaa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
    },
    poi_poaa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
    },
    poi_poaa_axo_2: {
        image: "%axo_2%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
    },
    poi_poaa_orbit: {
        orbit: "%orbit%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
        leftMenu: leftMenuHouse,
        compas: true,
    },

    visit: {
        tour: '%tour%',
        // leftMenu: leftMenuTour,
        // back: "scene",
    },

    sky: {
        // tour: '%tour%',
        // compas: true,
        // fullHeight : true,
        leftMenu: leftMenuBase,
        iframe: "https://hive.viewin360.co/share/collection/7qVQ8?logo=bWVkaWEvOTI5MzEvNjIzNC05NTc3LTAyNzYtZjE4MC5qcGc=&info=0&logosize=100&fs=1&vr=0&zoom=1&thumbs=-1&alpha=0.60&inst=fr"
        // leftMenu: leftMenuTour,
        // back: "scene",
    },

    // Sub orbital
    base_lot: {
        orbit: "%orbit%",
        compas: true,
        back: "scene",
        searchButton: true,
        typeFilter: true,
    },

    roof: {
        orbit: "%orbit%",
        compas: true,
        leftMenu: leftMenuBase,
        searchButton: true,
        typeFilter: true,
    },

    base: {
        orbit: "%orbit%",
        compas: true,
        // leftMenu: leftMenuBase,
        searchButton: true,
        // typeFilter: true,
    },

    house_orbit: {
        orbit: "%orbit%",
        compas: true,
        leftMenu: houseMenu,
        searchButton: true,
        typeFilter: true,
        back: "masse",
    },

    building_orbit: {
        orbit: "%orbit%",
        compas: true,
        leftMenu: buildingMenu,
        searchButton: true,
        typeFilter: true,
        back: "masse",
    },

    search: {
        back: "scene",
        backColor: "FFFFFF",
        search: true,
    },

    favorites: {
        back: "scene",
        favorites: true,
    }
}